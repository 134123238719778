import Sidebar from "../components/Sidebar";
import { Container, Row, Col, Card, Form, Button, Toast, ToastContainer } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { useToast } from 'rc-toastr';
import "react-datepicker/dist/react-datepicker.css";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import Topbar from "../components/Topbar";
import { useHistory } from "react-router-dom";
import MediaGallery from '../components/MediaGallery';
import CategoryTree from '../components/CategoryTree';
import Footer from "../components/Footer";
import AddBoxIcon from '@mui/icons-material/AddBox';
import setting from '../setting.json';


const Create = () => {
    const history = useHistory();
    const [list, setList] = useState([]);
    const [content, setContent] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [usr, setUsr] = useState(JSON.parse(localStorage.getItem('user')));
    const { toast } = useToast();

    const [show, setShow] = useState(false);
    const [cats, setCats] = useState([]);
    const [catts, setCatts] = useState([]);
    const newscategory = [];
    const selectCategory = (event) => {

        if (event.target.checked === false) {
            const index = cats.indexOf(event.target.value);
            if (index > -1) {
                cats.splice(index, 1);
            }
        } else {
            cats.push(event.target.value);
        }
        console.log(cats);
        //setCatts(cats);
        //setCategory(cats);
        //console.log(category);
    }

    const generateMeta = (event) => {
        var text = event.target.value;
        document.getElementById('slugtxt').value = text;
        document.getElementById('metades').value = text;
        document.getElementById('alt_meta').value = text;
    }

    const showMetaInfo = (event) => {
        if (event.target.checked === true) {
            document.getElementById('meta1').style.display = "block";
            document.getElementById('meta2').style.display = "block";
            document.getElementById('slug').style.display = "block";
            document.getElementById('meta4').style.display = "block";
        } else {
            document.getElementById('meta1').style.display = "none";
            document.getElementById('meta2').style.display = "none";
            document.getElementById('slug').style.display = "none";
            document.getElementById('meta4').style.display = "none";
        }

    }
    const getCategories = async () => {
        var url = setting.api;
        var user = JSON.parse(localStorage.getItem('user')).id;
        //console.log(user);
        await fetch(url + '/api/getAllCategoriesByUserId/' + user)
            .then(response => response.json())
            .then(json => {
                setList(json);
            }
            );
    }



    useEffect(() => {

        getCategories();

        document.getElementById('meta1').style.display = "none";
        document.getElementById('meta2').style.display = "none";
        document.getElementById('slug').style.display = "none";
        document.getElementById('slugtxt').setAttribute('readonly', true);

        document.getElementById('meta4').style.display = "none";
    }, []);
    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2),
            hours = ("0" + date.getHours()).slice(-2),
            minutes = ("0" + date.getMinutes()).slice(-2);
        var dt = date.getFullYear() + '-' + mnth + '-' + day + ' ' + hours + ':' + minutes + ':00';
        return dt;
    }
    const saveNews = async (event) => {
        event.preventDefault();
        //var date = new Date(startDate);
        document.getElementById('publish').innerHTML = 'Uploading...';
        document.getElementById('publish').setAttribute("disabled", true);
        var title = document.getElementById('title').value;
        if (title !== '') {
            try {
                const formData = new FormData();
                formData.append("title", title);
                formData.append("subtitle", document.getElementById('subtitle').value);
                formData.append("slug", document.getElementById('slugtxt').value);
                formData.append("pubdate", convert(startDate));

                formData.append("content", content);
                formData.append("category", cats);
                formData.append("metatag", document.getElementById('metatag').value);
                formData.append("metades", document.getElementById('metades').value);

                formData.append("alt_meta", document.getElementById('alt_meta').value);
                formData.append("group_id", document.getElementById('groupid').value);
                formData.append("user_id", JSON.parse(localStorage.getItem('user')).id);
                /* formData.append("image", image); */
                //console.log(formData);
                var url = setting.api;
                fetch(url + '/api/addNews', {
                    method: 'POST',
                    body: formData
                })
                    .then(response => response.json())
                    .then(json => {
                        console.log(json);

                        toast.success("Post Created Successfully.");
                        history.push('/editNews/' + json);
                    }
                    );
            } catch (err) {
                console.log(err);
                toast.success("Post Creation error.");
            }

        } else {
            alert('Please enter title');
        }

    }
    const saveAsDraftNews = async (event) => {
        event.preventDefault();
        try {
            const formData = new FormData();
            formData.append("title", document.getElementById('title').value);
            formData.append("subtitle", document.getElementById('subtitle').value);
            formData.append("slug", document.getElementById('slugtxt').value);
            formData.append("content", content);
            formData.append("category", cats);
            formData.append("metatag", document.getElementById('metatag').value);
            formData.append("metades", document.getElementById('metades').value);

            formData.append("alt_meta", document.getElementById('alt_meta').value);
            formData.append("group_id", document.getElementById('groupid').value);
            formData.append("user_id", JSON.parse(localStorage.getItem('user')).id);
            //console.log(formData);
            var url = setting.api;
            fetch(url + '/api/saveAsDraftNews', {
                method: 'POST',
                body: formData
            })
                .then(response => response.json())
                .then(json => {
                    toast.success("Post Created Successfully as Draft.");
                    history.push('/draftNews');
                }
                );
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Container fluid >
            <Row>
                <Col md={12} className="pad0">
                    <Topbar />
                </Col>

                <Sidebar />



                <Col md={10} lg={10} xs={12} className="rightsect">

                    <h3 className="pagetitle"><AddBoxIcon className="titleicon" /> Add News</h3>
                    <Form >
                        <Row>
                            <Col md={9} className="mb-20">
                                <Card>
                                    <Card.Body>

                                        <Form.Group className="mb-3" controlId="title">
                                            <Form.Control type="text" placeholder="Enter title" onChange={generateMeta} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="subtitle">

                                            <Form.Control type="text" placeholder="Enter sub title" />
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="content">

                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    ckfinder: {
                                                        //uploadUrl: setting.api + '/api/uploadImageFromCk'
                                                        uploadUrl: setting.api + '/api/uploadImageFromCk'
                                                    },
                                                    mediaEmbed: {
                                                        previewsInData: true,
                                                    }
                                                }}
                                                data=""

                                                onReady={editor => {
                                                    // You can store the "editor" and use when it is needed.
                                                    //console.log('Editor is ready to use!', editor);
                                                    // You can store the "editor" and use when it is needed.
                                                    // console.log("Editor is ready to use!", editor);
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            "height",
                                                            "350px",
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                                onChange={(event, editor) => {
                                                    const data = editor.getData();
                                                    setContent(data);
                                                    // console.log({ event, editor, data });
                                                }}
                                                onBlur={(event, editor) => {
                                                    //console.log('Blur.', editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    //console.log('Focus.', editor);
                                                }}

                                            />
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                                <Card className="mt-20">
                                    <Card.Header className="cardhead">Meta Information</Card.Header>
                                    <Card.Body>

                                        <Form.Group className="cardhead" controlId="custommeta">
                                            <Form.Check

                                                type='checkbox'
                                                className=""
                                                value=""
                                                label="Custom Meta Info"
                                                onChange={showMetaInfo}
                                            />
                                        </Form.Group>

                                        <Form.Group id="meta1" controlId="metatag">
                                            <Form.Label>Meta Tag (Seperated by comma )</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Meta Tag" />
                                        </Form.Group>
                                        <Form.Group id="meta2" controlId="metades">
                                            <Form.Label>Meta Description</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Meta Description" />
                                        </Form.Group>


                                        <Form.Group id="meta4" controlId="alt_meta" >
                                            <Form.Label>Image ALT Text</Form.Label>
                                            <Form.Control type="text" placeholder="Enter Image ALT Text" />
                                        </Form.Group>
                                        <Form.Group className="mb-3" id="slug" controlId="slugtxt">
                                            <Form.Label>Slug</Form.Label>
                                            <Form.Control type="text" placeholder="Enter slug" />
                                        </Form.Group>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={3} xs={12} >

                                <Card >
                                    <Card.Header className="cardhead">Category</Card.Header>
                                    <Card.Body>


                                        <div className="mb-3 catsect">
                                            <CategoryTree
                                                list={list}
                                                selectCategory={selectCategory}
                                                newscategory={newscategory}
                                            />
                                        </div>

                                    </Card.Body>
                                </Card>
                                <Card className="mt-20" >
                                    <Card.Header className="cardhead">Featured Image (jpg, png)</Card.Header>
                                    <Card.Body>
                                        <MediaGallery />
                                    </Card.Body>
                                </Card>
                                <Card className="mt-20">
                                    <Card.Header className="cardhead">Publish</Card.Header>
                                    <Card.Body >
                                        <Form.Group className="mb-3" controlId="schedule">
                                            <Button variant="outline-secondary" type="submit" id="draft" onClick={saveAsDraftNews}>Save as Draft</Button>{' '}
                                            <Button variant="outline-secondary" type="submit" id="preview">Preview</Button>{' '}

                                        </Form.Group>
                                        <Form.Group className="mb-3" id="meta4" controlId="schedule" >
                                            <Form.Label>Schedule At</Form.Label>
                                            <DatePicker
                                                className="form-control"
                                                selected={startDate}
                                                showTimeSelect
                                                dateFormat="Pp"

                                                timeIntervals="5"
                                                onChange={(date) => setStartDate(date)} />
                                        </Form.Group>

                                    </Card.Body>
                                    <Card.Footer className="cardhead">
                                        <div className="d-grid gap-2">

                                            {usr.role === 'editor' || usr.permission === '2' ? <Button variant="primary" type="submit" id="publish" onClick={saveNews}>Publish </Button> : ''}
                                        </div>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>


            <Footer />

        </Container >
    );
}
export default Create;